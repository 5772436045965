require('normalize.css/normalize.css');
require('../scss/index.scss');

// Write imports here


import Swiper, { Navigation, Pagination, EffectFade, EffectCoverflow } from 'swiper';
Swiper.use([Navigation, Pagination, EffectFade, EffectCoverflow]);
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


(function () {
  $(function () {

    // ============================================================================
    // ============================= CUSTOM CODE ==================================
    // ============================================================================

    // ______________________________
    // Swipers
    // ''''''''''''''''''''''''''''''

    const swiper = new Swiper('.banner__swiper', {
      // Optional parameters
      loop: true,
      effect: 'coverflow',

      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
      },

      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

      // And if we need scrollbar
      scrollbar: {
        el: '.swiper-scrollbar',
      },
    });

    const news = new Swiper('.news-swiper', {
      // Optional parameters
      slidesPerView: 1,
      loop: true,
      spaceBetween: 30,

      breakpoints: {
        1024: {
          slidesPerView: 3,
          spaceBetween: 60,
        },
        768: {
          spaceBetween: 20,
          slidesPerView: 3,
        },
        500: {
          slidesPerView: 2,
        },
      },
      // Navigation arrows
      navigation: {
        nextEl: '.news-button-next',
        prevEl: '.news-button-prev',
      },


    });

    //    Menu toggle

    $('#nav-icon2').on('click', function () {
      $(this).toggleClass('open');
      $('.header__mobile').slideToggle();
    });

    $('.header__mobile a').on('click', function () {
      $('.header__mobile').slideToggle();
      $('#nav-icon2').toggleClass('open');
    });


    //SOLICITAR CITA FORM
    $(".btn-pro").on("click", function () {
      var value = $(this).val();
      $("#id_step2-professional").val(value);
    });

    $('.btn-modal').on('click', function () {
      $('body').toggleClass('modal');
      $('#cita-modal').toggleClass('active');
    });
    $('.cita-modal__close').on('click', function () {
      $('body').toggleClass('modal');
      $('#cita-modal').toggleClass('active');
    });
    

    // ============================================================================
    // ============================ ON WINDOW LOAD ================================
    // ============================================================================

    $(window).on('load', function () {
      function resizeIframe(obj) {
        obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
      }

      $('body').removeClass('hidden');

    

      setTimeout(() => { // give time for aos

        // ______________________________
        // Run after visible
        // ''''''''''''''''''''''''''''''



        // ============================================================================
        // =========================== ON WINDOW SCROLL ===============================
        // ============================================================================

        var previousScroll = 0;

        $(window).on('scroll', function () {



        });

        // ============================================================================
        // =========================== ON WINDOW RESIZE ===============================
        // ============================================================================



        $(window).on('resize', function () {

        });

        // ============================================================================
        // ============================== ON BODY CLICK ===============================
        // ============================================================================

        $('body').on('click', (e) => {

          // console.log(e.target);

        });

      }, 400);

    });

  });
})();
